td.details-control {
    text-align: center;
    color: var(--green);
    cursor: pointer;
}

tr.shown td.details-control {
    text-align: center;
    color: var(--red);
}

#root {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    color: gray;
}

.tables {
    font-size: 0.9rem;
}

.table-wrapper {
    overflow: hidden;
    background: var(--white);
    padding: 20px 25px;
    margin: 30px auto;
    border-radius: 5px;
    box-shadow: 0 1px 1px var(--black);
    border-radius: 8px;
    border: 0px solid var(--gray);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 4px 0px var(--grey);
    box-shadow: 0px 1px 4px 0px var(--grey);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    width: 100%;
}

.table-wrapper .btn {
    float: right;
    color: gray;
    background-color: var(--white);
    border-radius: 3px;
    border: none;
    outline: none !important;
    margin-left: 1rem;
}

.table-wrapper .btn:hover {
    color: gray;
    background: var(--background);
}

.table-wrapper .btn.btn-primary {
    color: var(--white);
    background: var(--blue);
}

.table-wrapper .btn.btn-primary:hover {
    background: var(--blue);
}

#PassagesBtn {
    margin-bottom: 0.3rem;
}

.dataTables_scrollBody thead tr[role="row"] {
    visibility: collapse !important;
}

.linecolortable {
    height: 0.8rem;
    width: 150%;
    border-radius: 50px;
}

.formtablepassages {
    margin-top: 80px;
}

#searchFilter:disabled{
    cursor: not-allowed;
}
#PassagesBtn:disabled{

    cursor: not-allowed;
}
#newSearch:disabled{
    cursor: not-allowed;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-thumb {
    background-color:#414141;
    border-radius:5px;
}

::-webkit-scrollbar-track {
    background-color:var(--sidebar-background);
}