.login-form{
    width: 26rem;
    background-color: var(--white);
    border-radius: 8px;
    padding: 1rem;
    border: 0px solid var(--gray);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 4px 0px var(--grey);
    box-shadow: 0px 1px 4px 0px var(--grey);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    font: 500 0.90rem "Roboto", sans-serif;
}
.form{
    background-image: url('../template/img/1024.png');
    background-repeat: no-repeat;
    background-position: 4vh center;
}
.form-control{
    font-size: 0.9rem;
    border-color: var(--border-input);
}

.perfilUser{
   font-size: 1.3rem;
   margin: 0 auto;
   margin-top: 15px;
   margin-bottom: 15px;
   
}
.badge-perfil{
    background: var(--purple);
    color: var(--white);
}

.updateUser{
   font-size: 1.3rem;
   margin: 0 auto;
   margin-top: 15px;
   margin-bottom: 15px;
}
.badge-user{
    background: var(--pink-light);
    color: var(--white);
}
#updateProfile{
    background: var(--lilac);
    color: var(--white);
}
#usercreate:disabled{
    cursor: not-allowed;
}
#saveChanges:disabled{
    cursor: not-allowed;
}
#updateProfile:disabled{
    cursor: not-allowed;
}