#wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    position: relative;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--sidebar-background);
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}
.fa-user-edit{
    margin-right: 5px;
}
.fa-power-off{
    margin-right: 10px;
}
.sidebar-brand {
    position: absolute;
    top: 0;
    width: 250px;
    text-align: center;
    padding: 20px 0;
}

.sidebar-brand h2 {
    margin: 0;
    font-weight: 600;
    font-size: 10px;
    color: var(--white);
}

.sidebar-nav {
    position: absolute;
    top: 100px;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav>li {
    text-indent: 10px;
    line-height: 42px;
}

.sidebar-nav>li a {
    display: block;
    color: var(--side-li-a);
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.sidebar-nav>li>a:hover, .sidebar-nav>li.active>a {
    text-decoration: solid;
    color: var(--white);
    background:var(--red-sidebar);
    transform: translateX(5px);
}

.sidebar-nav>li>a {
    font-size: 18px;
    width: 240px;
}
.fa-home {
    font-size: 25px;
    margin-right: 16px;
}
.pageUserEdit{
    font-size: 25px;
    margin-right: 13px;
}
.fa-users-cog {
    font-size: 25px;
    margin-right: 13px;
}

.fa-bars {
    font-size: 25px;
}

.fa-sign-out-alt {
    margin-right: 3px;
}

.fa-plus {
    margin-right: 5px;
}
.fa-list-ul {
    font-size: 25px;
    margin-right: 20px;
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}

#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}

#navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: var(--grey);
    font-size: 24px;
    margin-bottom: 0;
    border-radius: 0;
}

#navbar-wrapper .navbar a {
    color: var(--side-li-a);
}

#navbar-wrapper .navbar a:hover {
    color: var(--side-li-a);
}

#content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    top: 100px;
}

#wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
}

@media (min-width: 992px) {
    #wrapper {
        padding-left: 250px;
    }
    #wrapper.toggled {
        padding-left: 60px;
    }
    #sidebar-wrapper {
        width: 250px;
    }
    #wrapper.toggled #sidebar-wrapper {
        width: 60px;
    }
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -190px;
    }
    #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -190px;
    }
    #navbar-wrapper {
        position: relative;
    }
    #wrapper.toggled {
        padding-left: 60px;
    }
    #content-wrapper {
        position: relative;
        top: 0;
    }
    #wrapper.toggled #navbar-wrapper, #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .logoutResp{
        display:block!important;
    }
    .fa-power-off{
        font-size: 23px;
        margin-right: 18px;
        margin-left: 1px;
    }
    #wrapper {
        padding-left: 60px;
    }
    #sidebar-wrapper {
        width: 60px;
    }
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #navbar-wrapper {
        position: relative;
    }
    #wrapper.toggled {
        padding-left: 250px;
    }
    #content-wrapper {
        position: relative;
        top: 0;
    }
    #wrapper.toggled #navbar-wrapper, #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
    }
}

@media (max-width: 767px) {
    .logoutResp{
        display:block!important;
    }
    .fa-power-off{
        font-size: 23px;
        margin-right: 18px;
        margin-left: 1px;
    }
    #wrapper {
        padding-left: 0;
    }
    #sidebar-wrapper {
        width: 0;
    }
    #wrapper.toggled #sidebar-wrapper {
        width: 250px;
    }
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #navbar-wrapper {
        position: relative;
    }
    #wrapper.toggled {
        padding-left: 250px;
    }
    #content-wrapper {
        position: relative;
        top: 0;
    }
    #wrapper.toggled #navbar-wrapper, #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
    }
}
#dropdownMenuButton{
    outline: none !important;
    box-shadow: none !important;
}
.dropdown-menu{
    background-color: var(--sidebar-background);
}
.dropdown-menu>a:hover{
    background:var(--red-sidebar);
    color:#ffff;
    transform: translateX(5px);
}
