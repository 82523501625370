.badge{
    font-size: 0.85em;
}
.username{
    margin-right: 15px;
    color: gray;
}
.email{
    margin-right: 15px;
    color: gray;
}
.password{
    margin-right: 15px;
    color: gray;
}
.date{
    margin-right: 15px;
    color: gray;
}
.lineColorlist{
    height: 0.8rem;
    width: 150%;
    border-radius: 50px;
}

.actions{
    display: inline;
}