:root{

    --background: #F2F3F5;
    --text: #666666;
    --white:#FFFFFF;
    --gray: #CAC9C9;
    --grey: #A8A8A8;
    --border-input:#E7E7E7;
    --side-li-a:#757575;
    --red-sidebar:#C03541;
    --sidebar-background:#2E313A;
    --black: #000000;
    --green:#15AC15;
    --red:#FA0000;
    --blue:#03A9F4;
    --purple:#894099;
    --pink-light:#DA1C5C;
    --lilac:#6E75B6;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;  
}
body{
    background-color: var(--background);
    color: var(--text);
    background-image: url('../img/BackgorundSemPararWeb.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
}

body, input, textarea, button{

    font: 400 1rem "Roboto", sans-serif;
}
button{
    cursor: pointer;
}

a{
    color: inherit;
    text-decoration: none;
}

@media(max-widh: 1080px){
    html{
        font-size: 93.75%;
    }
}
@media(max-widh: 720px){
    html{
        font-size: 87.5%;
    }
}